




































































import { Component, Watch } from "vue-property-decorator";
import Actions from "@/components/Actions.vue";
import CreateButton from "@/components/Button/CreateButton.vue";
import EditButton from "@/components/Button/EditButton.vue";
import RemoveButton from "@/components/Button/RemoveButton.vue";
import Table from "@/components/Table.vue";
import { namespace } from "vuex-class";
import { mixins } from "vue-class-component";
import ViewSwitchMixin from "@/mixins/ViewSwitchMixin";
import BranchView from "@/views/Branch/BranchView.vue";
import BranchForm from "@/views/Branch/BranchForm.vue";
import ArchiveButton from "@/components/Button/ArchiveButton.vue";
import type { IBranch } from "@/interfaces/IBranch";
import { IDayWorkHour } from "@/interfaces/IDayWorkHour";
import FscPageHeader from "@/components/FscPageHeader.vue";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import CompanyMixin from "@/mixins/CompanyMixin";
import ArchiveModal from "@/components/Modal/ArchiveModal.vue";
import ModalMixin from "@/mixins/ModalMixin";

const BranchModule = namespace("branch");
const BranchFilterModule = namespace("branch/localFilter");

@Component({
  components: {
    FscSimpleCard,
    FscPageHeader,
    ArchiveButton,
    BranchForm,
    BranchView,
    Table,
    RemoveButton,
    EditButton,
    CreateButton,
    Actions,
    ArchiveModal,
  },
})
export default class Branches extends mixins(ViewSwitchMixin, ModalMixin, CompanyMixin) {
  public name = "Branches";
  public fields = [
    {
      key: "abbreviation",
      label: this.$t("general.branch_abbreviation"),
      sortable: false,
    },
    {
      key: "name",
      label: this.$t("general.branch_name"),
      sortable: false,
      class: "text-center",
    },
    {
      key: "priceList",
      label: this.$t("general.branch_price_list"),
      sortable: false,
    },
  ];

  @BranchFilterModule.State("filter")
  public branchLocalFilter: any;

  @BranchFilterModule.Mutation("SET_LOCAL_FILTER")
  public setBranchLocalFilter!: (payload: Record<any, any>) => void;

  @BranchModule.Action("findAll")
  public findAllAction: any;

  @BranchModule.Getter("getDataList")
  public items: any;

  @BranchModule.Getter("getTotal")
  public totalRows: any;

  @BranchModule.Getter("getId")
  public createdId: any;

  @BranchModule.Action("create")
  public create: any;

  @BranchModule.Action("update")
  public update: any;

  @BranchModule.Action("findOne")
  public findOne: any;

  @BranchModule.Getter("getDataItem")
  public item: any;

  @BranchModule.Action("workHours")
  public workHoursAction: any;

  @BranchModule.Action("workHoursUpdate")
  public workHoursUpdateAction: any;

  @BranchModule.Getter("getWorkHours")
  public workHours: any;

  @BranchModule.Action("archive")
  public archive: any;

  @BranchModule.Getter("getArchiveSuccess")
  public archiveSuccess: any;

  @BranchModule.Getter("getSuccess")
  public success: any;

  @BranchModule.Getter("getIsLoading")
  public isLoading: any;

  public archived = false;

  public mounted() {
    if (this.branchLocalFilter instanceof Object) {
      this.archived = this.branchLocalFilter.archived;
    }
  }

  public requestQuery(query?: Record<any, any>): void {
    this.findAllAction({
      resource: "branches",
      params: { archived: this.archived },
    });
  }

  public async onRowClicked(ctx: any): Promise<void> {
    this.openView();
    this.selectedRow = ctx;
    await this.findOne({
      id: ctx.id,
      resource: "branches",
    });
    await this.workHoursAction(ctx.id);
  }

  public async onSubmit(item: IBranch): Promise<void> {
    if (this.typeCreate()) {
      await this.create({
        data: item,
        resource: "branches",
        descriptionField: "abbreviation",
      });
      await this.updateWorkHours(item.dayWorkHours, this.createdId);
    } else {
      await this.update({
        id: this.selectedRow.id,
        data: { id: this.selectedRow.id, ...item },
        resource: "branches",
        descriptionField: "abbreviation",
      });
      await this.updateWorkHours(item.dayWorkHours, this.selectedRow.id);
    }

    if (this.success) {
      this.requestQuery({});
    }
  }

  public get formLabel(): any {
    return this.typeCreate() ? this.$t("general.new_branch") : this.$t("general.edit_branch");
  }

  public get dataItem(): any {
    return this.typeCreate() ? {} : this.item;
  }

  public onChangeArchived(): void {
    this.setBranchLocalFilter({ archived: this.archived });
    this.requestQuery();
  }

  public async onArchived(): Promise<void> {
    await this.archive({ archived: true, id: this.selectedRow.id });
    this.onChangeArchived();
  }

  public get archivedOption(): Array<any> {
    return [
      {
        text: this.$tc("general.active", 2) + " " + this.$tc("general.branch", 2),
        value: false,
      },
      {
        text: this.$tc("general.archived", 2) + " " + this.$tc("general.branch", 2),
        value: true,
      },
    ];
  }

  public async updateWorkHours(item: Array<IDayWorkHour> | undefined, id : any): Promise<void> {
    await this.workHoursUpdateAction({
      id: id,
      dayWorkHours: item,
    });
  }

  public reformatName(row: any): string {
    return `${row.item.postalCode} - ${row.item.location}`;
  }

  private showArchiveModal(): void {
    if (!this.selectedRow) {
      return;
    }
    this.showModal("branch-archive-modal");
  }

  private getActiveWorkHours() : any {
    return this.workHours.filter((wh: any) => wh.active);
  }

  @Watch("archiveSuccess")
  private onArchiveSuccess(success: boolean): void {
    if (success) {
      this.requestQuery();
    }
  }
}
